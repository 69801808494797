.home_container {
    display: flex;
    height: 100%;
}

.home_wrapper_content {
    width: 100%;
    height: 100%;
    margin-top: 2rem;
}

.home_sidebar {
    height: 100%;
    /* position: fixed; */
    z-index: 9999;
    min-width: 300px;
    background-color: #0f0;
}
